import { makeStyles } from "@material-ui/core/styles";

import { theme } from "../theme";

export const useStyles = makeStyles(() => ({
  formBody: {
    // position: "relative",
    padding: "1rem",
    height: "100%",
    minHeight: "40vh",
    [theme.breakpoints.up("tablet")]: {
      padding: "3rem",
      paddingLeft: "0.5rem",
      minHeight: "40vh",
    },
  },
  formLineRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("mobile")]: {
      flexDirection: "row",
    },
  },
  formLineCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  submitButton: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  contactInfoBody: {
    backgroundColor: theme.palette.primary.main,
    padding: "1rem",
    margin: "5rem 0",
  },
  mobileContactInfoBody: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    width: "4rem",
    height: "4rem",
  },
  cardContent: {
    paddingBottom: "0 !important",
  },
  gridBody: {
    backgroundColor: theme.palette.primary.main,
    margin: "3.5rem 0",
    padding: "2rem",
  },
  brochureForm: {
    display: "flex",
    flexDirection: "column",
    padding: "0 2rem",
  },
  brochureLeftContent: {
    height: "100%",
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  btnSecondary: {
    margin: "1rem 0 0 0",
    borderWidth: "2px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    [theme.breakpoints.up("tablet")]: {
      margin: "1rem 3rem 0 3rem",
    },
  },
  fieldset: {
    border: "none",
    padding: "1.5rem 0",
  },
}));
