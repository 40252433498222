import React, { useState } from "react";

import { Link } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/Textfield";
import Button from "@material-ui/core/Button";
import { Checkbox } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
import GetAppIcon from "@material-ui/icons/GetApp";

import BorchurePDF from "../../assests/downloads/PCH Services-E-Book.pdf";

import { useStyles } from "./BrochureForm.styles";

const BrochureForm = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  const [brochureForm, setBrochureForm] = useState({ tcs: false });
  const [loading, setLoading] = useState(false);
  // const [formSent, setFormSent] = useState(false);
  const [token, setToken] = useState(null);

  const handleBrochureTextFields = (event) => {
    const { id, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setBrochureForm({ ...brochureForm, [id]: fieldValue });
  };

  const handleBrochureRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://pch-services.herokuapp.com/brochure", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...brochureForm, token: token }),
      });
      console.log(response);

      if (response.status === 200) {
        // setFormSent(true);
        window.location.href = BorchurePDF;
      }
      handleLoading();
    } catch (err) {
      handleLoading();
      console.log("error", err);
    }
  };

  const handleLoading = () => {
    setTimeout(() => {
      setLoading(false);
      setToken(null);
    }, 2000);
  };

  return (
    <form
      className={classes.brochureForm}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        handleBrochureRequest();
      }}
    >
      <TextField
        margin="dense"
        id="email"
        label="Email"
        defaultValue=""
        value={brochureForm["email"]}
        onChange={handleBrochureTextFields}
        required
      />
      <label>
        <Checkbox
          id="tcs"
          label="Terms and conditions"
          value={brochureForm["tcs"]}
          onChange={handleBrochureTextFields}
          required
        />
        <Link className={classes.link} to='/tcs'>
        Accept terms and conditions *
        </Link>
        
      </label>
      <label>
        <Checkbox
          id="contactMe"
          label="Contact Me"
          value={brochureForm["contactMe"]}
          onChange={handleBrochureTextFields}
        />
        Please contact me
      </label>
      {!loading ? (
        <Button
          classes={{ root: classes.btnSecondary }}
          variant="outlined"
          endIcon={<GetAppIcon />}
          disabled={loading || !token}
          type="submit"
        >
          Download
        </Button>
      ) : (
        <CircularProgress />
      )}
      <ReCAPTCHA
        sitekey="6LdToDAbAAAAAMLX3upzT_FCArhtkCc7GPPoXISE"
        onChange={(token) => setToken(token)}
        style={{
          left: !desktop && "50%",
          right: desktop && 0,
          position: "absolute",
          transform: desktop ? "translate(0, 0)" : "translate(-50%, 0)",
          bottom: desktop ? "0" : "15%",
          display: brochureForm?.tcs ? "inherit" : "none",
        }}
      />
    </form>
  );
};

export default BrochureForm;
