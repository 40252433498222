import React, { useState } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/Textfield";
import { Layout } from "../components/Layout";
import Button from "@material-ui/core/Button";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import Seo from "../components/Seo/Seo";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BrochureForm } from "../components/BrochureForm";

import ReCAPTCHA from "react-google-recaptcha";

import WindowCleanImg from "../images/service-grid/man_cleaning_windows.png";
import BrochureIcon from "../assests/svg/brochure-open.svg";

import { useStyles } from "../pagesStyles/callback.styling";

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Request Callback",
  },
];

const Callback = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [token, setToken] = useState(null);

  const handleTextFields = (event) => {
    const { id, value } = event.target;
    setForm({ ...form, [id]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://pch-services.herokuapp.com/callback', {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...form, token: token }),
      });
      console.log(response);

      if (response.status === 200) {
        setFormSent(true);
      }
      handleLoading();
    } catch (err) {
      handleLoading();
      console.log("error", err);
    }
  };

  const handleLoading = () => {
    setTimeout(() => {
      setLoading(false);
      setToken(null);
    }, 2000);
  };

  return (
    <Layout marginTop breadcrumb={breadcrumbs}>
      <Seo
        title="Call Back"
        description=" Leave a message for PCH Services to contact you or download one of our brochures to find out more."
      />
      <section style={{ padding: desktop ? "0 5%" : 0 }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid
            item
            xs={desktop ? 6 : 12}
            style={{
              minHeight: desktop ? "500px" : "600px",
              position: "relative",
            }}
          >
            {formSent && !loading ? (
              <div style={{ minHeight: "100px" }}>
                <Typography variant="h2" align={desktop ? "left" : "center"}>
                  Thank You - Message Sent
                </Typography>
              </div>
            ) : (
              <form
                className={classes.formBody}
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <fieldset className={classes.fieldset}>
                  <div className={classes.formLineRow}>
                    <TextField
                      margin="dense"
                      id="firstname"
                      label="First Name"
                      defaultValue=""
                      value={form["firstname"]}
                      onChange={handleTextFields}
                      required
                    />
                    <TextField
                      margin="dense"
                      id="surname"
                      label="Surname"
                      defaultValue=""
                      value={form["surname"]}
                      onChange={handleTextFields}
                      required
                    />
                  </div>
                  <div className={classes.formLineRow}>
                    <TextField
                      margin="dense"
                      id="telephone"
                      label="Telephone"
                      defaultValue=""
                      value={form["telephone"]}
                      onChange={handleTextFields}
                      required
                    />
                    <TextField
                      margin="dense"
                      id="email"
                      label="Email"
                      defaultValue=""
                      value={form["email"]}
                      onChange={handleTextFields}
                      required
                    />
                  </div>
                  <div className={classes.formLineCol}>
                    <TextField
                      margin="dense"
                      id="message"
                      multiline
                      rowsMax={3}
                      defaultValue=""
                      value={form["message"]}
                      onChange={handleTextFields}
                      label="Message"
                      required
                    />
                  </div>
                </fieldset>
                {!loading ? (
                  <Button
                    classes={{ root: classes.submitButton }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading || !token}
                    endIcon={<SendIcon />}
                    style={{bottom: form.message?.length > 5 ? '15%': '25%' }}
                  >
                    Submit
                  </Button>
                ) : (
                  <CircularProgress />
                )}
                <ReCAPTCHA
                  sitekey="6LdToDAbAAAAAMLX3upzT_FCArhtkCc7GPPoXISE"
                  onChange={(token) => setToken(token)}
                  style={{
                    left: "50%",
                    position: "absolute",
                    transform: "translate(-50%, 0)",
                    bottom: "25%",
                    display: form.message?.length > 5 ? "inherit" : "none",
                  }}
                />
              </form>
            )}
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={WindowCleanImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <section className={classes.gridBody}>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <div className={classes.brochureLeftContent}>
              <Typography align={"center"}>
                <SvgIcon className={classes.icon}>
                  {React.createElement(BrochureIcon)}
                </SvgIcon>
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align={"center"}
              >
                Download a brochure
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={desktop ? 6 : 12}
            style={{
              minHeight: desktop ? "200px" : "300px",
              position: "relative",
            }}
          >
            <BrochureForm />
          </Grid>
        </Grid>
      </section>
    </Layout>
  );
};

export default Callback;
