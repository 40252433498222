import { makeStyles } from "@material-ui/core/styles";

import { theme } from "../../theme";

export const useStyles = makeStyles(() => ({
  brochureForm: {
    display: "flex",
    flexDirection: "column",
    padding: "0 2rem",
  },
  btnSecondary: {
    margin: "1rem 0 0 0",
    borderWidth: "2px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    [theme.breakpoints.up("tablet")]: {
      margin: "1rem 3rem 0 3rem",
    },
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    bottom: "0%",
    [theme.breakpoints.up("tablet")]: {
      left: "15%",
    },
  },
  link: {
    color: "black",
    textDecoration: "none",
    textTransform: "Capitalize",
    whiteSpace: "nowrap",
    display: "inline-block",
    "&::after": {
      content: '""',
      display: "block",
      width: 0,
      height: "2px",
      backgroundColor: "black",
      transition: "width .3s",
    },
    "&:hover::after": {
      width: "100%",
    },
  },
}));
